﻿/* DISTRICT PRO FONTS */
@font-face {
    font-family: "DistrictPro";
    src: url('/fonts/district_pro_family/GarageFontsDistrictPro-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: "DistrictPro";
    src: url('/fonts/district_pro_family/GarageFontsDistrictPro-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: "DistrictPro";
    src: url('/fonts/district_pro_family/GarageFontsDistrictPro-Light.woff') format('woff');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: "DistrictPro";
    src: url('/fonts/district_pro_family/GarageFontsDistrictPro-LightItalic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: "DistrictPro";
    src: url('/fonts/district_pro_family/GarageFontsDistrictPro-Book.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "DistrictPro";
    src: url('/fonts/district_pro_family/GarageFontsDistrictPro-BookItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: "DistrictPro";
    src: url('/fonts/district_pro_family/GarageFontsDistrictPro-Medium.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "DistrictPro";
    src: url('/fonts/district_pro_family/GarageFontsDistrictPro-MediumItalic.woff') format('woff');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: "DistrictPro";
    src: url('/fonts/district_pro_family/GarageFontsDistrictPro-Demi.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "DistrictPro";
    src: url('/fonts/district_pro_family/GarageFontsDistrictPro-DemiItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: "DistrictPro";
    src: url('/fonts/district_pro_family/GarageFontsDistrictPro-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "DistrictPro";
    src: url('/fonts/district_pro_family/GarageFontsDistrictPro-BoldItalic.woff') format('woff');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: "DistrictPro";
    src: url('/fonts/district_pro_family/GarageFontsDistrictPro-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: "DistrictPro";
    src: url('/fonts/district_pro_family/GarageFontsDistrictPro-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
}
