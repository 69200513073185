﻿.scoreperfect .hidden-sp {
    display: none;
}

.testmasters .hidden-tm {
    display: none;
}

body {
    min-height: 100vh;
    display: grid;
    grid-template-rows: auto 1fr auto;
}

.page-header{
    margin-bottom:1rem;
    @extend .border-bottom;
    h1{
        font-size:2rem;
    }
}


.header {
    background-color: #1F2350;
    font-family: "DistrictPro", roboto, sans-serif;
    font-size: 16px;
    font-weight: 300;
    padding-top: 0.75rem;
    padding-bottom: 1rem;

    .header-inside {
        margin-left: auto;
        margin-right: auto;

        #logo {
            font-feature-settings: 'kern' 1;
            font-size: 2rem;
            font-weight: 300;
            line-height: 1;
            margin-bottom: 0.5rem;

            a {
                margin: 0;
                color: white;
                text-decoration: none;
            }
        }

        #slogan {
            height: 14px;
            width: 189px;
            background-image: url(/images/logo/Tagline.svg);
            background-size: cover;
            filter: drop-shadow(0 0 1px rgba(255,255,255,.25));
        }
    }
}

.footer {
    border-top: solid 1px #aaa;
    border-bottom: solid 1px #aaa;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;

    p {
        font-size: 0.75rem;
        line-height: 1;
        text-align: center;
        margin: 0;
        color: #aaa;
    }
}

/* Validation */
.field-validation-error {
    @extend .invalid-feedback;
}

.field-validation-valid {
    display: none;
}

input.input-validation-error, select.input-validation-error {
    @extend .is-invalid;
}

input[type="checkbox"].input-validation-error {
    border: 0 none;
}

.validation-summary-errors {
    @extend .alert;
    @extend .alert-danger;

    ul {
        margin-bottom: 0;
    }
}

.validation-summary-valid {
    display: none;
}
