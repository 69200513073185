﻿

// Filters at the top of any list
.filter-row {
    @extend .row;
    @extend .g-2;

    label {
        font-size: 0.875rem;
        font-weight: $form-label-font-weight;
        @extend .mb-1;
    }
    /* Filters */
    .col-button {
        @extend .d-flex;
        @extend .flex-column;
        @extend .justify-content-end;
        @extend .pt-2;
        @extend .pt-sm-0;
        // assures button isn't too crunched
        .btn-sm {
            --bs-btn-padding-x: 1rem;
        }
    }

    .col-links {
        @extend .d-flex;
        @extend .flex-column;
        @extend .justify-content-end;
        @extend .text-center;
        @extend .text-sm-end;
    }

    .col-checks {
        @extend .d-flex;
        @extend .flex-column;
        @extend .justify-content-end;

        .form-check-label {
            font-size: 0.875rem;
            font-weight: normal;
        }
    }
    // static information
    .form-data-static {
        @extend .pt-0;
        @extend .pt-sm-1;
        font-size: 0.875rem;
    }

    .col-date {
        @extend .col-3;
        @extend .col-md-2;
    }
}
